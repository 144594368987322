<script setup lang="ts">

</script>

<template>
  <h4 class="font-bold text-2xl leading-tight">
    <slot></slot>
  </h4>
</template>

<style scoped>

</style>
